import { RudderAnalytics } from "@rudderstack/analytics-js";

export type Analytics = { page(): void };

let analytics: Analytics = {
  page() {
    console.warn("analytics.page() skipped due to missing configuration");
  },
};

if (
  import.meta.env.PUBLIC_RUDDERSTACK_WRITE_KEY &&
  import.meta.env.PUBLIC_RUDDERSTACK_DATA_PLANE_URL
) {
  const rudder = new RudderAnalytics();

  rudder.load(
    import.meta.env.PUBLIC_RUDDERSTACK_WRITE_KEY,
    import.meta.env.PUBLIC_RUDDERSTACK_DATA_PLANE_URL,
    {},
  );

  window.rudderanalytics = rudder;
  analytics = rudder;
}

export { analytics };
